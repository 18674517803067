// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = (props: PageProps) => (
  <Layout>
    <SEO title="Page two" />
    <div className="mx-auto max-w-7xl py-12 sm:px-6 lg:px-8">
      <h1 className="text-4xl tracking-tight font-extrabold text-gray-200 sm:text-5xl md:text-6xl">
        Services
      </h1>
      <p className="text-xl my-10 text-gray-400">
        We offer a range of basic Design services. Seriously, we got you!
      </p>
      {/* <p className="text-white">Welcome to ({props.path})</p> */}
      <div className="grid grid-rows-2 grid-flow-col gap-1">
        <div className="flex items-center w-full h-72 p-12 bg-gray-900 text-center text-xl text-gray-500 hover:bg-gray-700">
          <div className="grid auto-rows text-center w-full">
            <h3 className="text-white text-2xl mb-2">Brand & Strategy</h3>
            <p>description here</p>
          </div>
        </div>
        <div className="flex items-center w-full h-72 p-12 bg-gray-900 text-center text-xl text-gray-500 hover:bg-gray-700">
          <h3 className="text-white">Marketing</h3>
          <p>description here</p>
        </div>
        <div className="flex items-center w-full h-72 p-12 bg-gray-900 text-center text-xl text-gray-500 hover:bg-gray-700">
          <h3 className="text-white">User Experience</h3>
          <p>description here</p>
        </div>
        <div className="flex items-center w-full h-72 p-12 bg-gray-900 text-center text-xl text-gray-500 hover:bg-gray-700">
          <h3 className="text-white">Web Design</h3>
          <p>description here</p>
        </div>
        <div className="flex items-center w-full h-72 p-12 bg-gray-900 text-center text-xl text-gray-500 hover:bg-gray-700">
          <h3 className="text-white">Content Management systems</h3>
          <p>description here</p>
        </div>
        <div className="flex items-center w-full h-72 p-12 bg-gray-900 text-center text-xl text-gray-500 hover:bg-gray-700">
          <h3 className="text-white">Application Design</h3>
          <p>description here</p>
        </div>
      </div>
    </div>
    {/* <Link to="/">Take me home</Link> */}
  </Layout>
)

export default SecondPage
